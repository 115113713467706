@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.smart-table {
  min-width: 100%;
  display: grid;
  gap: 15px;

  input {
    &:focus {
      outline-color: #00000033;
      box-shadow: none;
      border-color: transparent;
    }

    &:disabled {
      background-color: #ececec;
    }
  }

  .input {
    padding: 9px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #E2E2E2;
  }

  td, td > * {
    font-family: 'Open Sans', sans-serif;
  }
}
