@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.vol {
  min-width: 100%;
  display: grid;
  gap: 15px;

  .data-tab {
    background-color: var(--primary);
    color: white;
    min-width: 120px;
  }

  .data-tab.base--selected {
    background-color: var(--logogreen);
  }

  td, td > * {
    font-family: 'Open Sans', sans-serif;
  }
}
